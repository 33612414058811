import { Typography } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';
import { Box } from '../Box';

export interface DateInputProps {
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
}

export const DateInput: React.FC<DateInputProps> = ({
  label,
  onChange,
  value,
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };
  return (
    <Box>
      <Typography>{label}</Typography>
      <Box mt={1} />
      <input
        autoComplete="false"
        type={'date'}
        value={value}
        onChange={onInputChange}
      />
    </Box>
  );
};
