import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactElement, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '../../Box';
import { TextField } from '../../inputs/TextField';
import { Link } from '../../Link';
import { ToggleOpen } from '../../ToggleOpen';
import { Typography } from '../../Typography';
import { Table } from '../Table';
import { TableBody } from '../TableBody';
import { TableCell } from '../TableCell';
import { TableContainer } from '../TableContainer';
import { TablePagination } from '../TablePagination';
import { TableRow } from '../TableRow';
import { SortablePaginatedTableCell } from './SortablePaginatedTableCell';
import { SortablePaginatedTableHead } from './SortablePaginatedTableHead';
import { defaultSearch, TableHeadCell } from './TableHeadCell';
import { Order } from './types';
import {
  Comparator,
  getComparator as getComparatorFunc,
  getRowsPerPageOptions,
  stableSort,
} from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    searchContainer: {
      padding: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

interface SortablePaginatedTableProps<T extends {}> {
  dense?: boolean;
  getComparator?: Comparator<T>;
  hasReachedPenultimatePage?: () => void;
  initialOrder?: Order;
  initialOrderByKey: keyof T;
  initialRowsPerPage: number;
  rows: T[];
  searchEnabled?: boolean;
  tableHeadCells: TableHeadCell<T>[];
  title: string;
  titleLinkUrl?: string;
}

export const SortablePaginatedTable = <T extends { id: string }>({
  dense = true,
  getComparator = getComparatorFunc,
  hasReachedPenultimatePage,
  initialOrder = 'asc',
  initialOrderByKey,
  initialRowsPerPage = 5,
  rows,
  searchEnabled = true,
  tableHeadCells,
  title,
  titleLinkUrl,
}: SortablePaginatedTableProps<T>): ReactElement<
  SortablePaginatedTableProps<T>
> => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = React.useState<keyof T>(initialOrderByKey);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    Math.min(initialRowsPerPage, rows.length)
  );
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(true);

  useEffect(() => {
    setRowsPerPage(Math.min(initialRowsPerPage, rows.length));
  }, [rows.length, initialRowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if ((newPage + 2) * rowsPerPage > rows.length) {
      if (hasReachedPenultimatePage) {
        hasReachedPenultimatePage();
      }
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const currentFirst = rowsPerPage * page;
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.floor(currentFirst / newRowsPerPage));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const onSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const searchFilter = (row: T) =>
    tableHeadCells.some((cell) =>
      cell.search
        ? cell.search(row, cell.id, searchTerm)
        : defaultSearch(row, cell.id, searchTerm)
    );

  const filteredRows = stableSort(rows, getComparator(order, orderBy)).filter(
    searchFilter
  );

  const rowsPerPageOptions = getRowsPerPageOptions(
    initialRowsPerPage,
    filteredRows.length
  );

  return (
    <div className={classes.root}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        display={'flex'}
        p={2}
      >
        <Box>
          <Box>
            {titleLinkUrl ? (
              <Link component={RouterLink} to={titleLinkUrl}>
                {title}
              </Link>
            ) : (
              <Typography variant="h5" id="tableTitle" component="div">
                {title}
              </Typography>
            )}
          </Box>
          {searchEnabled && (
            <TextField
              label={'Search'}
              value={searchTerm}
              onChange={onSearchChange}
            />
          )}
        </Box>
        <ToggleOpen open={open} onToggle={setOpen} />
      </Box>
      {open && (
        <div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <SortablePaginatedTableHead
                headCells={tableHeadCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover key={row.id}>
                        {tableHeadCells.map((cell, index) => (
                          <SortablePaginatedTableCell
                            dense={dense}
                            key={index}
                            first={index === 0}
                            maxWidth={cell.maxWidth}
                          >
                            {cell.render(row)}
                          </SortablePaginatedTableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 21 : 53) * emptyRows }}>
                    <TableCell colSpan={tableHeadCells.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rowsPerPageOptions.length > 0 && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      )}
    </div>
  );
};
