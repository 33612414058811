import React from 'react';
import { CircularProgress } from './progress/CircularProgress';
import { Button, ButtonProps } from './Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/core';
import { Box } from './Box';
import { Theme } from './theme';

export interface ButtonWithLoadingProps extends ButtonProps {
  loading: boolean;
  minWidth?: string;
}

const useStyles = makeStyles<Theme, Pick<ButtonWithLoadingProps, 'minWidth'>>(
  (theme) =>
    createStyles({
      container: {
        minWidth: ({ minWidth }) => minWidth,
      },
    })
);

export const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  children,
  loading,
  minWidth,
  ...props
}) => {
  const classes = useStyles({ minWidth });
  return (
    <Box className={classes.container}>
      <Button {...props} fullWidth={true}>
        {loading ? <CircularProgress size={24} /> : children}
      </Button>
    </Box>
  );
};
