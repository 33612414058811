import React from 'react';
import { ProductRentalType } from '../../common/types/product';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';

export interface RentalTypeSelectProps {
  id: string;
  value?: string;
  onChange: (
    value: ProductRentalType,
    event: React.ChangeEvent<{ value: unknown }>
  ) => void;
}

export const rentalTypeItems: { label: any; value: ProductRentalType }[] = [
  {
    label: 'Rent',
    value: 'rent',
  },
  {
    label: 'Buy',
    value: 'buy',
  },
];

export const RentalTypeSelect: React.FC<RentalTypeSelectProps> = ({
  id,
  onChange,
  value,
}) => {
  const onRentalTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as ProductRentalType, event);
  };

  return (
    <Select
      label={'Rental type'}
      id={id}
      value={value}
      onChange={onRentalTypeChange}
    >
      {rentalTypeItems.map((color) => (
        <MenuItem key={color.value} value={color.value}>
          {color.label}
        </MenuItem>
      ))}
    </Select>
  );
};
