import React from 'react';
import { IconButton } from './IconButton';
import { ChevronDown } from './icons/ChevronDown';
import { ChevronUp } from './icons/ChevronUp';

interface Props {
  open: boolean;
  onToggle: (open: boolean) => void;
}

export const ToggleOpen: React.FC<Props> = ({ open, onToggle }) => {
  const onToggleOpen = () => {
    onToggle(!open);
  };

  return (
    <IconButton aria-label="delete">
      {open ? (
        <ChevronUp onClick={onToggleOpen} />
      ) : (
        <ChevronDown onClick={onToggleOpen} />
      )}
    </IconButton>
  );
};
