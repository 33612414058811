import React from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarCloseReason,
  SnackbarProps as MUISnackbarProps,
} from '@material-ui/core';

interface SnackbarProps extends MUISnackbarProps {}

export const Snackbar: React.FC<SnackbarProps> = (props) => {
  return <MUISnackbar {...props} />;
};

export type SnackbarOnClose = (
  event: React.SyntheticEvent<any>,
  reason: SnackbarCloseReason
) => void;
