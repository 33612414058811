import { connectAuthEmulator, getAuth } from 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppWithProviders } from './AppWithProviders';
import { initializeFirebase } from './common/auth/firebaseConfig';
import { initI18N } from './i18n';
import './index.css';

import reportWebVitals from './reportWebVitals';

initializeFirebase();
initI18N();

if (process.env.USE_EMULATOR) {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
}

ReactDOM.render(
  <React.StrictMode>
    <AppWithProviders />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
