import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePriceWeightByIdQuery } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { EditPriceWeightDrawer } from './EditPriceWeightDrawer';

interface Props {}

const useStyles = makeStyles({
  table: {
    width: 650,
  },
});

export const PriceWeightPage: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data, loading } = usePriceWeightByIdQuery({ variables: { id } });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (!data?.payment?.priceWeightById) {
    return <div>No price weight found</div>;
  }

  const priceWeight = data.payment.priceWeightById;
  const priceWeights = [
    { weight: priceWeight.start, label: 'Start' },
    { weight: priceWeight.firstHour, label: 'First Hour' },
    { weight: priceWeight.secondHour, label: 'Second Hour' },
    { weight: priceWeight.thirdHour, label: 'Third Hour' },
    { weight: priceWeight.fourthHour, label: 'Fourth Hour' },
    { weight: priceWeight.fifthHour, label: 'Fifth Hour' },
    { weight: priceWeight.sixthHour, label: 'Sixth Hour' },
    { weight: priceWeight.penalty, label: 'Penalty' },
  ];

  const onOpenEdit = () => {
    setOpen(true);
  };
  const onCloseEdit = () => {
    setOpen(false);
  };

  return (
    <Paper>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Hour</TableCell>
                  <TableCell align="right">Price weight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceWeights.map(({ label, weight }, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{weight}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Button onClick={onOpenEdit}>Edit</Button>
        </Box>
      </Box>
      <EditPriceWeightDrawer
        priceWeight={priceWeight}
        open={open}
        onClose={onCloseEdit}
      />
    </Paper>
  );
};
