import { Typography } from '@material-ui/core';
import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { User } from '../../common/types/user';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';

interface Props {
  user: User;
}

export const RolesList: React.FC<Props> = ({ user }) => {
  if (user.roles.length === 0) {
    return <Typography>This user is not assigned any roles</Typography>;
  }

  return (
    <>
      {user.roles.map((role) => (
        <Box>
          <Link
            component={RouterLink}
            to={generatePath(Routes.ROLE, { id: role.id })}
          >
            {role.name}
          </Link>
        </Box>
      ))}
    </>
  );
};
