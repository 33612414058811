import { Typography } from '@material-ui/core';
import React from 'react';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Rental } from '../../../common/types/rental';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { SortablePaginatedTable } from '../../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../../webui/table/sortable-paginated-table/TableHeadCell';
import { generateNumberOfRentalsPerBoxMap } from '../rentals/utils/generateNumberOfRentalsPerBoxMap';

interface Props {
  boxes: BoxWithoutRentals[];
  rentals: Rental[];
}

interface PublicUsersPerBoxTableData {
  id: string;
  box: string;
  share: number;
}

const createTableHeadCells =
  (): TableHeadCell<PublicUsersPerBoxTableData>[] => [
    {
      id: 'box',
      label: 'Box',
      render: (row) => row.box,
    },
    {
      id: 'share',
      label: 'Share',
      render: (row) => `${row.share}%`,
    },
  ];

export const PublicUsersPerBoxTable: React.FC<Props> = ({ boxes, rentals }) => {
  const numberOfRentalsPerBoxEntries = Object.entries(
    generateNumberOfRentalsPerBoxMap(rentals)
  )
    .map(([boxId, numberOfUsers]) => {
      const box = boxes.find((b) => b.id === boxId);
      return {
        id: boxId,
        box: box ? resolveBoxDisplayName(box) : boxId,
        share: Math.round((numberOfUsers / rentals.length) * 100),
      };
    })
    .filter((value) => value.share !== 0);

  if (numberOfRentalsPerBoxEntries.length <= 0) {
    return <Typography>{'There were no users during this period'}</Typography>;
  }

  return (
    <SortablePaginatedTable
      title={'Andel pifflare per box'}
      initialRowsPerPage={numberOfRentalsPerBoxEntries.length}
      initialOrderByKey={'share'}
      initialOrder={'desc'}
      rows={numberOfRentalsPerBoxEntries}
      searchEnabled={false}
      tableHeadCells={createTableHeadCells()}
    />
  );
};
