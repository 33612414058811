import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useNotification } from '../../common/notifications/useNotification';
import { CopyIcon } from '../../webui/icons/CopyIcon';
import { AllRentalData } from './types';
import { getDisplayRental } from './utils/getDisplayRental';

interface Props {
  rental: AllRentalData;
}

const useStyles = makeStyles({
  icon: {
    padding: 0,
  },
});

export const CopyRentalButton: React.FC<Props> = ({ rental }) => {
  const { showNotification } = useNotification();

  const onClick = async () => {
    const message = getDisplayRental(rental);
    await navigator.clipboard.writeText(message);
    showNotification({
      message: 'Rental copied to clipboard',
      severity: 'success',
    });
  };

  const classes = useStyles();

  return (
    <IconButton className={classes.icon} onClick={onClick}>
      <CopyIcon />
    </IconButton>
  );
};
