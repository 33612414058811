import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from './common/Routes';
import { ActionsPage } from './features/actions/ActionsPage';
import { AllActivityAreas } from './features/activity-area/AllActivityAreas';
import { AddPage } from './features/add/AddPage';
import { BoxBatteryHistoryPage } from './features/battery-status/BoxBatteryHistoryPage';
import { AllBoxes } from './features/box/AllBoxes';
import { BoxPage } from './features/box/BoxPage';
import { AllFeedbacks } from './features/feedback/AllFeedbacks';
import { AllOrganisations } from './features/organisation/AllOrganisations';
import { OrganisationPage } from './features/organisation/OrganisationPage';
import { AllPendingUsers } from './features/pending-user/AllPendingUsers';
import { PendingUserPage } from './features/pending-user/PendingUserPage';
import { AllPermissions } from './features/permission/AllPermissions';
import { AllPriceWeights } from './features/price/AllPriceWeights';
import { PricePage } from './features/price/PricePage';
import { PriceWeightPage } from './features/price/PriceWeightPage';
import { AllProducts } from './features/product/AllProducts';
import { ProductPage } from './features/product/ProductPage';
import { AllRentalsWithSearch } from './features/rentals/AllRentalsWithSearch';
import { RentalPage } from './features/rentals/RentalPage';
import { AllReviews } from './features/review/AllReviews';
import { ReviewPage } from './features/review/ReviewPage';
import { AllRoles } from './features/role/AllRoles';
import { RolePage } from './features/role/RolePage';
import { AllSponsors } from './features/sponsor/AllSponsors';
import { SponsorPage } from './features/sponsor/SponsorPage';
import { StatisticsPage } from './features/statistic/StatisticsPage';
import { EditTranslationsPage } from './features/translations/EditTranslationsPage';
import { AllUsersWithSearch } from './features/user/AllUsersWithSearch';
import { UserPage } from './features/user/UserPage';

interface AppLoggedInProps {}

export const AppLoggedIn: React.FC<AppLoggedInProps> = () => (
  <div>
    <Switch>
      <Route path={Routes.ACTIONS}>
        <ActionsPage />
      </Route>
      <Route path={Routes.ALL_ACTIVITIES}>
        <AllActivityAreas />
      </Route>
      <Route path={Routes.ALL_BATTERY_VOLTAGES}>
        <BoxBatteryHistoryPage />
      </Route>
      <Route path={Routes.ALL_BOXES}>
        <AllBoxes />
      </Route>
      <Route path={Routes.ALL_PRODUCTS}>
        <AllProducts />
      </Route>
      <Route path={Routes.ALL_RENTALS}>
        <AllRentalsWithSearch />
      </Route>
      <Route path={Routes.ALL_ROLES}>
        <AllRoles />
      </Route>
      <Route path={Routes.ALL_SPONSORS}>
        <AllSponsors />
      </Route>
      <Route path={Routes.ALL_USERS}>
        <AllUsersWithSearch />
      </Route>
      <Route path={Routes.ALL_REVIEWS}>
        <AllReviews />
      </Route>
      <Route path={Routes.ALL_FEEDBACKS}>
        <AllFeedbacks />
      </Route>
      <Route path={Routes.ALL_ORGANISATIONS}>
        <AllOrganisations />
      </Route>
      <Route path={Routes.ALL_PERMISSIONS}>
        <AllPermissions />
      </Route>
      <Route path={Routes.ALL_PENDING_USERS}>
        <AllPendingUsers />
      </Route>
      <Route path={Routes.ALL_PRICE_WEIGHTS}>
        <AllPriceWeights />
      </Route>
      <Route path={Routes.PRICE}>
        <PricePage />
      </Route>
      <Route path={Routes.BOX}>
        <BoxPage />
      </Route>
      <Route path={Routes.PRODUCT}>
        <ProductPage />
      </Route>
      <Route path={Routes.ROLE}>
        <RolePage />
      </Route>
      <Route path={Routes.STATISTICS}>
        <StatisticsPage />
      </Route>
      <Route path={Routes.RENTAL}>
        <RentalPage />
      </Route>
      <Route path={Routes.REVIEW}>
        <ReviewPage />
      </Route>
      <Route path={Routes.SPONSOR}>
        <SponsorPage />
      </Route>
      <Route path={Routes.USER}>
        <UserPage />
      </Route>
      <Route path={Routes.ADD}>
        <AddPage />
      </Route>
      <Route path={Routes.ORGANISATION}>
        <OrganisationPage />
      </Route>
      <Route path={Routes.PENDING_USER}>
        <PendingUserPage />
      </Route>
      <Route path={Routes.PRICE_WEIGHT}>
        <PriceWeightPage />
      </Route>
      <Route path={Routes.TRANSLATIONS}>
        <EditTranslationsPage />
      </Route>
    </Switch>
  </div>
);
