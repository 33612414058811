import {
  createStyles,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Theme,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { INPUT_WIDTH } from './utils';

interface SelectProps extends MUISelectProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: INPUT_WIDTH,
    },
  })
);

export const Select: React.FC<SelectProps> = ({ label, ...props }) => {
  const classes = useStyles();
  const labelId = `${props.id}-label`;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MUISelect {...props} labelId={labelId} placeholder={'Välj'} />
    </FormControl>
  );
};
