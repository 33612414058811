import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';
import { Typography } from './Typography';

export interface DataListItem {
  value?: React.ReactNode;
  label: string;
}

interface Props {
  header?: string;
  headerVariant?: Variant;
  items: DataListItem[];
}

export const DataList: React.FC<Props> = ({
  header,
  headerVariant = 'h3',
  items,
}) => (
  <Grid>
    {header && <Typography variant={headerVariant}>{header}</Typography>}
    <List dense={true}>
      {items.map((item) => (
        <ListItem key={item.label}>
          <ListItemText
            color={'textSecondary'}
            primary={item.label}
            secondary={item.value}
          />
        </ListItem>
      ))}
    </List>
  </Grid>
);
