import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar } from '../../webui/AppBar';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Grid } from '../../webui/Grid';
import { IconButton } from '../../webui/IconButton';
import { AccountCircle } from '../../webui/icons/AccountCircle';
import { Link } from '../../webui/Link';
import { Menu } from '../../webui/Menu';
import { MenuItem } from '../../webui/MenuItem';
import { Typography } from '../../webui/Typography';
import { AuthUser } from '../auth/AuthUser';
import { Routes } from '../Routes';
import { HeaderLink, headerLinks } from './headerLinks';
import { ResponsivePageLinksMenu } from './ResponsivePageLinksMenu';
import { sortHeaderLinks } from './sortHeaderLinks';

interface HeaderProps {
  loggedIn: boolean;
  onLogIn: () => void;
  onLogOut: () => void;
  user: AuthUser | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: '48px',
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const responsiveHeaderLinks = sortHeaderLinks(headerLinks);
const isMostUsedLink = (link: HeaderLink) =>
  [
    Routes.ACTIONS,
    Routes.ALL_ACTIVITIES,
    Routes.ALL_BOXES,
    Routes.ALL_PRODUCTS,
    Routes.ALL_RENTALS,
    Routes.STATISTICS,
    Routes.ALL_USERS,
  ].includes(link.path);

const desktopBurgerHeaderLinks = sortHeaderLinks(
  headerLinks.filter((link) => !isMostUsedLink(link))
);
const desktopHeaderLinks = sortHeaderLinks(headerLinks.filter(isMostUsedLink));

export const Header: React.FC<HeaderProps> = ({
  loggedIn,
  onLogIn,
  onLogOut,
  user,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:1024px)');

  const [
    loggedInMenuAnchorEl,
    setLoggedInMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(loggedInMenuAnchorEl);

  const handleOpenLoggedInMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLoggedInMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLoggedInMenuAnchorEl(null);
  };

  const handleLogout = () => {
    setLoggedInMenuAnchorEl(null);
    onLogOut();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Grid
          container
          alignItems="center"
          justify={'space-between'}
          className={classes.grid}
        >
          <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
            {mobile ? (
              <ResponsivePageLinksMenu headerLinks={responsiveHeaderLinks} />
            ) : (
              <ResponsivePageLinksMenu headerLinks={desktopBurgerHeaderLinks} />
            )}
            <Container>
              <Link color={'inherit'} component={RouterLink} to={Routes.HOME}>
                Piffl
              </Link>
            </Container>
            <Divider orientation="vertical" flexItem />
            {!mobile &&
              desktopHeaderLinks.map((link, index) => (
                <React.Fragment key={link.path}>
                  {index !== 0 && <Divider orientation="vertical" flexItem />}
                  <Container>
                    <Link
                      noWrap={true}
                      color={'inherit'}
                      component={RouterLink}
                      to={link.path}
                    >
                      {link.label}
                    </Link>
                  </Container>
                </React.Fragment>
              ))}
          </Box>
          <div>
            {loggedIn ? (
              <>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="logged-in-menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenLoggedInMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="logged-in-menu-appbar"
                  anchorEl={loggedInMenuAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <div>
                    <Box p={2}>
                      <Typography>{user?.email ?? ''}</Typography>
                    </Box>
                  </div>
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </Menu>
              </>
            ) : (
              <Button variant={'contained'} onClick={onLogIn}>
                Log in
              </Button>
            )}
          </div>
        </Grid>
      </AppBar>
    </div>
  );
};
