import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React from 'react';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllPriceWeights } from './gql/useAllPriceWeights';

interface PriceWeightSelectProps {
  id: string;
  onChange: SelectInputProps['onChange'];
  value: string;
}

export const PriceWeightSelect: React.FC<PriceWeightSelectProps> = ({
  id,
  value,
  onChange,
}) => {
  const { data: priceWeights, loading } = useAllPriceWeights();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Select id={id} label={'Price weight'} value={value} onChange={onChange}>
      {priceWeights.map((priceWeight) => (
        <MenuItem key={priceWeight.id} value={priceWeight.id}>
          {priceWeight.name}
        </MenuItem>
      ))}
    </Select>
  );
};
