import React from 'react';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { AllReviewsTable } from './AllReviewsTable';

interface Props {}

export const AllReviews: React.FC<Props> = () => {
  const {
    data: { rentals },
    loading,
    error,
  } = useAllRentals({
    variables: {
      filter: {
        hasReview: true,
      },
    },
  });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Paper>
      <AllReviewsTable rentals={rentals} />
    </Paper>
  );
};
