import React from 'react';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllSponsors } from './gql/useAllSponsors';
import { sortSponsors } from './utils/sortSponsors';

interface SponsorSelectProps {
  id: string;
  value: string;
  onChange: (
    value: string,
    evenet: React.ChangeEvent<{ value: unknown }>
  ) => void;
}

interface SponsorItem {
  label: string;
  value: string;
}

export const SponsorSelect: React.FC<SponsorSelectProps> = ({
  id,
  onChange,
  value,
}) => {
  const { data: sponsors, loading, error } = useAllSponsors();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const sponsorItems: SponsorItem[] = sortSponsors(sponsors).map((sponsor) => ({
    label: sponsor.name,
    value: sponsor.id,
  }));

  const onSponsorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string, event);
  };

  return (
    <Select label={'Sponsor'} id={id} value={value} onChange={onSponsorChange}>
      {sponsorItems.map((sponsor) => (
        <MenuItem key={sponsor.value} value={sponsor.value}>
          {sponsor.label}
        </MenuItem>
      ))}
    </Select>
  );
};
