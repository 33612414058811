import { ApolloError } from '@apollo/client';
import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { ActivityArea } from '../../common/types/activityArea';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { ActivityAreaData } from './types/ActivityAreaData';

const createTableHeadCells: TableHeadCell<ActivityAreaData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (row) => {
      return (
        <Link
          component={RouterLink}
          to={generatePath(Routes.ACTIVITY, {
            id: row.id,
          })}
        >
          {row.id}
        </Link>
      );
    },
  },
  {
    id: 'activities',
    label: 'Activities',
    render: (row) => row.activities.join(', '),
  },
  {
    id: 'location',
    label: 'Location',
    render: (row) => {
      if (!row.location) {
        return '';
      }

      return `${row.location.name} (${row.location.coordinates?.lat}, ${row.location.coordinates?.long})`;
    },
  },
  {
    id: 'type',
    label: 'Type',
    render: (row) => row.type,
  },
  {
    id: 'verified',
    label: 'Verified',
    render: (row) => row.verified,
  },
  {
    id: 'active',
    label: 'Active',
    render: (row) => (row.active ? 'Active' : ''),
  },
];

const createRows = (activityAreas: ActivityArea[]): ActivityAreaData[] =>
  activityAreas.map((activityArea) => {
    return {
      id: activityArea.id,
      active: activityArea.active,
      activities: activityArea.activities,
      location: activityArea.location,
      type: activityArea.type,
      verified: activityArea.verified,
    };
  });

interface Props {
  activityAreas: ActivityArea[];
  error?: ApolloError;
  loading?: boolean;
}

export const ActivityAreasTable: React.FC<Props> = ({
  activityAreas,
  error,
  loading,
}) => {
  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Box my={2}>
      <Paper>
        <SortablePaginatedTable
          title={'Activity Areas'}
          initialRowsPerPage={20}
          initialOrderByKey={'id'}
          tableHeadCells={createTableHeadCells}
          rows={createRows(activityAreas)}
        />
      </Paper>
    </Box>
  );
};
