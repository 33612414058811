import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LatLngExpression } from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

interface Props {
  height?: string;
  initialPosition: LatLngExpression;
  zoom?: number;
}

const useStyles = makeStyles<Theme, { height: string }>((theme: Theme) =>
  createStyles({
    mapContainer: {
      height: ({ height }) => height,
    },
  })
);

export const ZOOM_ONE_MARKER = 13;
export const ZOOM_MANY_MARKERS = 6;

export const GenericMap: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  height = '600px',
  initialPosition,
  zoom = 13,
}) => {
  const classes = useStyles({ height });

  return (
    <MapContainer
      center={initialPosition}
      zoom={zoom}
      scrollWheelZoom={false}
      className={classes.mapContainer}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  );
};
