import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Rental } from '../../../common/types/rental';
import { Box } from '../../../webui/Box';
import {
  generateRatingsData,
  RatingData,
} from '../rentals/utils/generateRatingsData';
import { getSharesInPercentage } from '../utils/getSharesInPercentage';
import { RatingItem } from './RatingItem';

interface Props {
  rentals: Rental[];
}

const values: (keyof RatingData['values'])[] = [5, 4, 3, 2, 1];

export const PublicRatings: React.FC<Props> = ({ rentals }) => {
  const ratingsData = generateRatingsData(rentals);
  return (
    <div>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Andel pifflare som betygsätter efter uthyrning'}
          secondary={`${getSharesInPercentage(
            ratingsData.numberOfRatings,
            ratingsData.numberOfRentals
          )}%`}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Genomsnittligt betyg'}
          secondary={ratingsData.averageRating}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Andel stjärnor'}
          secondary={
            <Box>
              {values.map((value) => (
                <RatingItem
                  amount={ratingsData.values[value]}
                  numberOfRatings={ratingsData.numberOfRatings}
                  value={value}
                />
              ))}
            </Box>
          }
        />
      </ListItem>
    </div>
  );
};
