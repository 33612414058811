import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../../common/Routes';
import { useDeleteBoxMutation } from '../../../generated/graphql';
import { Button } from '../../../webui/Button';
import { ButtonWithLoading } from '../../../webui/ButtonWithLoading';
import { Dialog } from '../../../webui/Dialog';
import { DialogActions } from '../../../webui/DialogActions';
import { DialogContent } from '../../../webui/DialogContent';
import { DialogContentText } from '../../../webui/DialogContentText';
import { DialogTitle } from '../../../webui/DialogTitle';
import { Switch } from '../../../webui/inputs/Switch';

const useStyles = makeStyles((theme) =>
  createStyles({
    deleteButton: {
      backgroundColor: theme.palette.error.light,
    },
  })
);

interface Props {
  id: string;
}

export const DeleteBoxButton: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [deleteBox, { loading: loadingDeleteBox }] = useDeleteBoxMutation({
    refetchQueries: ['allBoxes'],
    awaitRefetchQueries: true,
  });
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onDeleteBox = async () => {
    await deleteBox({
      variables: {
        boxId: id,
      },
    });

    history.push(Routes.ALL_BOXES);
  };

  return (
    <>
      <Switch
        onChange={setButtonEnabled}
        value={buttonEnabled}
        label={'Delete box'}
      />
      <Button
        disabled={!buttonEnabled}
        className={classes.deleteButton}
        onClick={handleOpen}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete the box forever?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you really sure? This action is not reversible...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            I changed my mind
          </Button>
          <ButtonWithLoading
            loading={loadingDeleteBox}
            onClick={onDeleteBox}
            color="primary"
            autoFocus
          >
            Delete it
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
};
