import { createStyles, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import Select, { Props } from 'react-select';
import { Box } from '../Box';

export interface Option {
  label: string;
  value: string;
}

export interface MultiSearchSelectProps extends Props<Option, true> {
  label?: string;
}

export interface SearchSelectProps extends Props<Option, false> {
  label?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.text.secondary,
    },
    select: {
      minWidth: '300px',
    },
  })
);

export const SearchSelect: React.FC<SearchSelectProps> = ({
  label,
  ...props
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  return (
    <div>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        className={classes.select}
        {...props}
        onInputChange={(v, action) => {
          if (action.action !== 'set-value') {
            setInputValue(v);
          }
        }}
        inputValue={inputValue}
        placeholder={'Välj'}
      />
    </div>
  );
};

export const MultiSearchSelect: React.FC<MultiSearchSelectProps> = ({
  label,
  ...props
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  return (
    <Box>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        isMulti={true}
        className={classes.select}
        {...props}
        onInputChange={(v, action) => {
          if (action.action !== 'set-value') {
            setInputValue(v);
          }
        }}
        placeholder={'Välj'}
        inputValue={inputValue}
      />
    </Box>
  );
};
