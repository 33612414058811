import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Typography } from '../../webui/Typography';
import { CompartmentsThatNeedsAction } from './CompartmentsThatNeedsAction';
import { NotStoppedRentals } from './NotStoppedRentals';
import { RentalActionsTableWithSearch } from './RentalActionsTableWithSearch';
import { UnpaidRentals } from './UnpaidRentals';

interface Props {}

export const ActionsPage: React.FC<Props> = () => {
  const [showUnpaidRentals, setShowUnpaidRentals] = useState(false);
  const [showNotStoppedRentals, setShowNotStoppedRentals] = useState(false);

  const onToggleShowUnpaidRentals = () => {
    setShowUnpaidRentals((prevState) => !prevState);
  };
  const onToggleShowNotStoppedRentals = () => {
    setShowNotStoppedRentals((prevState) => !prevState);
  };

  return (
    <Container>
      <Paper>
        <Container>
          <CompartmentsThatNeedsAction />
        </Container>
      </Paper>
      <Box mt={2}>
        <Paper>
          <RentalActionsTableWithSearch />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <Box display={'flex'} p={2} justifyContent={'center'}>
            <Button onClick={onToggleShowNotStoppedRentals}>
              {showNotStoppedRentals
                ? 'Hide not stopped rentals'
                : 'Show not stopped rentals'}
            </Button>
          </Box>
          {showNotStoppedRentals && (
            <Container>
              <Box pt={1} pb={1}>
                <Typography variant="h6">Not stopped rentals</Typography>
                <NotStoppedRentals />
              </Box>
            </Container>
          )}
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <Box display={'flex'} p={2} justifyContent={'center'}>
            <Button onClick={onToggleShowUnpaidRentals}>
              {showUnpaidRentals
                ? 'Hide unpaid rentals'
                : 'Show unpaid rentals'}
            </Button>
          </Box>
          {showUnpaidRentals && (
            <Container>
              <UnpaidRentals />
            </Container>
          )}
        </Paper>
      </Box>
    </Container>
  );
};
