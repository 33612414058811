import React from 'react';
import { Typography } from '../../webui/Typography';
import { Container } from '../../webui/Container';
import { Box } from '../../webui/Box';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Link } from '../../webui/Link';
import { useAllProducts } from './gql/useAllProducts';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Product } from './Product';
import { Paper } from '../../webui/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '../../webui/theme';
import { sortProducts } from './utils/sortProducts';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  })
);

export const AllProducts: React.FC<Props> = () => {
  const classes = useStyles();
  const { data: products, loading, error } = useAllProducts();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are now currently {products.length} products for people to
            fool around with!
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_PRODUCT}>
            Add new product
          </Link>
        </Box>
      </Box>
      <div className={classes.root}>
        {sortProducts(products).map((product) => (
          <Paper className={classes.paper} key={product.id}>
            <Product product={product} />
          </Paper>
        ))}
      </div>
    </Container>
  );
};
