import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { useNotification } from '../../common/notifications/useNotification';
import { useTranslatedProduct } from '../product/utils/useTranslatedProduct';
import { AllRentalData } from './types';
import { generateBoxActionInfo } from './utils/generateBoxActionInfo';

interface Props {
  rental: AllRentalData;
}

const useStyles = makeStyles({
  icon: {
    padding: 0,
  },
});

export const PostBoxActionButton: React.FC<Props> = ({ rental }) => {
  const { showNotification } = useNotification();
  const { tName } = useTranslatedProduct();

  const onClick = async () => {
    const token = await getAuth().currentUser?.getIdToken();

    const resp = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/box-action`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          generateBoxActionInfo(rental, tName(rental.product, 'sv'))
        ),
      }
    );
    if (resp.status === 200) {
      showNotification({
        message: 'Box action added',
        severity: 'success',
      });
    } else {
      showNotification({
        message: 'Box action could not be added',
        severity: 'error',
      });
    }
  };

  const classes = useStyles();

  return (
    <IconButton className={classes.icon} onClick={onClick}>
      <img
        src={`/notion-logo.svg`}
        alt={'notion-logo'}
        width={24}
        height={24}
      />
    </IconButton>
  );
};
