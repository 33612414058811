import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { TableCell } from '../TableCell';

interface Props {
  dense: boolean;
  first: boolean;
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, { maxWidth?: number }>({
  cell: {
    whiteSpace: 'nowrap',
    overflow: ({ maxWidth }) => (maxWidth ? 'hidden' : 'auto'),
    textOverflow: ({ maxWidth }) => (maxWidth ? 'ellipsis' : 'clip'),
    maxWidth: ({ maxWidth }) => maxWidth,
  },
});

export const SortablePaginatedTableCell: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, dense, first, maxWidth }) => {
  const classes = useStyles({ maxWidth });

  if (first) {
    return (
      <TableCell
        component="th"
        scope="row"
        padding={dense ? 'checkbox' : 'default'}
      >
        <div className={classes.cell}>{children}</div>
      </TableCell>
    );
  } else {
    return (
      <TableCell padding={dense ? 'checkbox' : 'default'}>
        <div className={classes.cell}>{children}</div>
      </TableCell>
    );
  }
};
