import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Theme,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {
  FormControlLabelProps
} from '@material-ui/core/FormControlLabel/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

export interface CheckboxWithLabelProps
  extends Pick<FormControlLabelProps, 'label' | 'labelPlacement'> {
  onChange: (value: boolean, event: React.ChangeEvent<{}>) => void;
  value: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {},
    formControlLabel: {
      marginLeft: 0,
      justifyContent: 'flex-end',
    },
  })
);

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  label,
  labelPlacement,
  onChange,
  value,
}) => {
  const classes = useStyles();

  const onFormControlChange = (event: React.ChangeEvent<{}>) => {
    onChange(!value, event);
  };

  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        className={classes.formControlLabel}
        value={value}
        control={<Checkbox checked={value} color="primary" />}
        label={label}
        labelPlacement={labelPlacement}
        onChange={onFormControlChange}
      />
    </FormControl>
  );
};
