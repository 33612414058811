import React from 'react';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllProducts } from './gql/useAllProducts';
import { sortProducts } from './utils/sortProducts';

export interface ProductSelectProps {
  id: string;
  value: string;
  onChange: (
    value: string,
    event: React.ChangeEvent<{ value: unknown }>
  ) => void;
}

interface ProductItem {
  label: string;
  value: string;
}

export const ProductSelect: React.FC<ProductSelectProps> = ({
  id,
  onChange,
  value,
}) => {
  const { data: products, loading, error } = useAllProducts();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const productItems: ProductItem[] = sortProducts(products).map((product) => ({
    label: product.name,
    value: product.id,
  }));

  const onProductChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string, event);
  };

  return (
    <Select label={'Product'} id={id} value={value} onChange={onProductChange}>
      {productItems.map((product) => (
        <MenuItem key={product.value} value={product.value}>
          {product.label}
        </MenuItem>
      ))}
    </Select>
  );
};
