import React from 'react';
import { Pie, PieChart, Tooltip } from 'recharts';
import { Rental } from '../../../common/types/rental';
import {
  generateRentalsPerGenderPieData,
  RentalsPerGenderPieData,
} from './utils/generateRentalsPerGenderPieData';

interface Props {
  isPublic?: boolean;
  rentals: Rental[];
}

export const RentalsPerGenderPie: React.FC<Props> = ({
  isPublic = false,
  rentals,
}) => {
  return (
    <PieChart width={600} height={600}>
      <Pie
        data={generateRentalsPerGenderPieData(rentals)}
        dataKey="count"
        label={(data: RentalsPerGenderPieData) =>
          data.count >= 1 ? data.gender : undefined
        }
        nameKey="gender"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#B40A7A"
      />
      <Tooltip
        // @ts-ignore
        formatter={(
          value: number,
          name: string,
          props: { payload: RentalsPerGenderPieData }
        ) => {
          return isPublic
            ? `${props.payload.share}%`
            : `${props.payload.count} (${props.payload.share}%)`;
        }}
      />
    </PieChart>
  );
};
