import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Rental } from '../../common/types/rental';
import { Review } from '../../common/types/review';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { Link } from '../../webui/Link';
import { Rating } from '../../webui/Rating';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';
import { filterUndefinedReviews } from './utils/filterRentalsWithReviews';

interface Props {
  rentals: Rental[];
}

export interface ReviewData {
  id: string;
  rating?: number;
  freeText?: string;
  date?: string;
  rental: Rental;
}

const userHeadCells: TableHeadCell<ReviewData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.REVIEW, {
          id: row['id'],
        })}
      >
        {row['id']}
      </Link>
    ),
  },
  {
    id: 'rating',
    label: 'Rating',
    render: (value) => {
      return <Rating readOnly={true} value={value.rating ?? 0} />;
    },
  },
  {
    id: 'freeText',
    label: 'Free text',
    render: (value) => value['freeText'],
  },
  {
    id: 'date',
    label: 'Rent end',
    render: (value) =>
      value.rental.rentEnd ? toDisplayDateTime(value.rental.rentEnd) : '',
  },
  {
    id: 'rental',
    label: 'Rental',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.RENTAL, {
          id: value.rental.id,
        })}
      >
        {value['id']}
      </Link>
    ),
  },
];

const INITIAL_ROWS_PER_PAGE = 10;

interface RentalWithReview extends Rental {
  review: Review;
}

const createRows = (rentals: RentalWithReview[]): ReviewData[] => {
  return rentals.map((rental) => {
    return {
      id: rental.review.id,
      rating: rental.review.rating,
      freeText: rental.review.freeText,
      date: rental.rentEnd,
      rental,
    };
  });
};

const reviewComparator: Comparator<ReviewData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const AllReviewsTable: React.FC<Props> = ({ rentals }) => {
  return (
    <SortablePaginatedTable
      getComparator={reviewComparator}
      title={'Reviews'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'id'}
      rows={createRows(filterUndefinedReviews(rentals))}
      tableHeadCells={userHeadCells}
    />
  );
};
