import { ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Box } from '../../../webui/Box';
import { Paper } from '../../../webui/Paper';
import { LoadingSpinnerContainer } from '../../../webui/progress/LoadingSpinnerContainer';
import { useAllRentals } from '../../rentals/gql/useAllRentals';
import { Filter } from '../filter/types';
import { transformFilterToAllRentalsGQLFilter } from '../filter/utils/transformFilterToAllRentalsGQLFilter';
import { ProductsPie } from '../products/ProductsPie';
import { getRentalsPerUser } from '../users/utils';
import { Ratings } from './Ratings';
import { RentalsPayments } from './RentalsPayments';
import { RentalsPerBoxPerDay } from './RentalsPerBoxPerDay';
import { RentalsPerBoxPie } from './RentalsPerBoxPie';
import { RentalsPerBoxTable } from './RentalsPerBoxTable';
import { RentalsPerDay } from './RentalsPerDay';
import { RentalsPerGenderPie } from './RentalsPerGenderPie';
import { RentalsPerProductTable } from './RentalsPerProductTable';
import { RentalTime } from './RentalTime';

interface Props {
  allBoxes: BoxWithoutRentals[];
  filter?: Filter;
}

export const StatisticsRental: React.FC<Props> = ({ allBoxes, filter }) => {
  const {
    data: { rentals },
    loading,
    error,
    refetch,
  } = useAllRentals({
    variables: {
      filter: transformFilterToAllRentalsGQLFilter(filter),
    },
  });

  useEffect(() => {
    refetch()
      .then(() => {})
      .catch((error) => {
        console.log('---', error, '--- error');
      });
  }, [filter, refetch]);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const averageTime = 14;

  const showAdvancedCharts =
    filter?.selectedBoxes &&
    filter.selectedBoxes.length > 0 &&
    filter.selectedBoxes.length < 20;

  return (
    <Box>
      <Paper>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Number of rentals total'}
            secondary={rentals.length}
          />
        </ListItem>
      </Paper>
      <Box mt={2} />
      <Paper>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Number of unique rental users'}
            secondary={getRentalsPerUser(rentals).length}
          />
        </ListItem>
      </Paper>
      <Box mt={2} />
      <Paper>
        <RentalsPayments rentals={rentals} />
      </Paper>
      <Box mt={2} />
      <Paper>
        <Ratings rentals={rentals} />
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>{`Number of rentals per day (all boxes). Average time: ${averageTime}`}</Typography>
          </Box>
          <RentalsPerDay rentals={rentals} averageTime={averageTime} />
        </Box>
      </Paper>
      {showAdvancedCharts && (
        <>
          <Box mt={2} />
          <Paper>
            <Box p={2}>
              <Box m={2}>
                <Typography>Number of rentals per day (per box).</Typography>
              </Box>
              <RentalsPerBoxPerDay rentals={rentals} />
            </Box>
          </Paper>
        </>
      )}
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>Number of rentals per box.</Typography>
          </Box>
          <RentalsPerBoxTable
            rentals={rentals}
            boxes={allBoxes}
            fromDate={filter?.fromDate}
            endDate={filter?.endDate}
          />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>Number of rentals per product.</Typography>
          </Box>
          <RentalsPerProductTable rentals={rentals} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>{`Product share in rentals`}</Typography>
          </Box>
          <ProductsPie rentals={rentals} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>Time spent during the rentals</Typography>
          </Box>
          <RentalTime rentals={rentals} />
        </Box>
      </Paper>
      <Box mt={2} flexDirection={'row'}>
        {showAdvancedCharts && (
          <>
            <Paper>
              <Box p={2}>
                <Box m={2}>
                  <Typography>Rentals per box</Typography>
                </Box>
                <RentalsPerBoxPie boxes={allBoxes} rentals={rentals} />
              </Box>
            </Paper>
            <Box mb={2} />
          </>
        )}
        <Paper>
          <Box p={2}>
            <Box m={2}>
              <Typography>Rentals per gender</Typography>
            </Box>
            <RentalsPerGenderPie rentals={rentals} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
