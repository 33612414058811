import { Typography } from '@material-ui/core';
import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../../common/Routes';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Rental } from '../../../common/types/rental';
import { differenceInDays } from '../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { roundToDigits } from '../../../common/utils/roundToDigits';
import { Link } from '../../../webui/Link';
import { SortablePaginatedTable } from '../../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../../webui/table/sortable-paginated-table/TableHeadCell';
import { generateNumberOfRentalsPerBoxMap } from './utils/generateNumberOfRentalsPerBoxMap';

interface Props {
  rentals: Rental[];
  boxes: BoxWithoutRentals[];
  endDate: string | undefined;
  fromDate: string | undefined;
}

interface BoxData {
  id: string;
  name: string;
}

interface RentalsPerBoxTableData {
  id: string;
  box: BoxData;
  numberOfCompartments: number;
  numberOfRentals: number;
  numberOfRentalsPerCompartment: number;
  averageRentalsPerDay: number | string;
  averageRentalsPerDayPerCompartment: number | string;
  share: number;
}

const createTableHeadCells = (): TableHeadCell<RentalsPerBoxTableData>[] => [
  {
    id: 'box',
    label: 'Box',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.BOX, {
          id: row['box'].id,
        })}
      >
        {row['box'].name}
      </Link>
    ),
  },
  {
    id: 'numberOfCompartments',
    label: `#Compartments`,
    render: (row) => row.numberOfCompartments,
  },
  {
    id: 'numberOfRentals',
    label: `#Rentals`,
    render: (row) => row.numberOfRentals,
  },
  {
    id: 'numberOfRentalsPerCompartment',
    label: `#Rentals per compartment`,
    render: (row) => roundToDigits(row.numberOfRentalsPerCompartment, 1),
  },
  {
    id: 'averageRentalsPerDay',
    label: 'Average per day',
    render: (row) => row.averageRentalsPerDay,
  },
  {
    id: 'averageRentalsPerDayPerCompartment',
    label: 'Average per day per compartment',
    render: (row) => row.averageRentalsPerDayPerCompartment,
  },
  {
    id: 'share',
    label: 'Share',
    render: (row) => `${row.share}%`,
  },
];

export const RentalsPerBoxTable: React.FC<Props> = ({
  rentals,
  boxes,
  endDate,
  fromDate,
}) => {
  const end = endDate ? new Date(endDate) : new Date();
  const from = fromDate ? new Date(fromDate) : undefined;

  const numberOfRentalsPerBoxEntries = Object.entries(
    generateNumberOfRentalsPerBoxMap(rentals)
  ).reduce((acc, [boxId, numberOfRentals]) => {
    const box = boxes.find((b) => b.id === boxId);
    if (!box) {
      return acc;
    }

    const start =
      from ??
      (box.initialActivationTime ? new Date(box.initialActivationTime) : null);
    const numberOfDaysSinceActivation = start
      ? differenceInDays(end, start) + 1
      : null;

    const averageRentalsPerDay = numberOfDaysSinceActivation
      ? roundToDigits(numberOfRentals / numberOfDaysSinceActivation, 2)
      : 'No activation date for box';

    const averageRentalsPerDayPerCompartment = numberOfDaysSinceActivation
      ? roundToDigits(
          numberOfRentals /
            numberOfDaysSinceActivation /
            box.compartments.length,
          3
        )
      : 'No activation date for box';
    return [
      ...acc,
      {
        id: boxId,
        box: {
          id: boxId,
          name: resolveBoxDisplayName(box),
        },
        numberOfCompartments: box.compartments.length,
        numberOfRentals,
        numberOfRentalsPerCompartment:
          numberOfRentals / box.compartments.length,
        averageRentalsPerDay,
        averageRentalsPerDayPerCompartment,
        share: Math.round((numberOfRentals / rentals.length) * 100),
      },
    ];
  }, [] as RentalsPerBoxTableData[]);

  if (numberOfRentalsPerBoxEntries.length <= 0) {
    return (
      <Typography>{`There were no rentals during this period`}</Typography>
    );
  }

  return (
    <SortablePaginatedTable
      title={`Number of rentals per box`}
      initialRowsPerPage={numberOfRentalsPerBoxEntries.length}
      initialOrderByKey={'averageRentalsPerDay'}
      initialOrder={'desc'}
      tableHeadCells={createTableHeadCells()}
      rows={numberOfRentalsPerBoxEntries}
    />
  );
};
