import { createStyles, makeStyles } from '@material-ui/core/styles';
import Editor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';
import * as React from 'react';
import { useEffect, useRef } from 'react';

interface Props {
  onChange: (json: any) => void;
  json: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '70vh',
    },
  })
);

export const JSONEditor: React.FC<Props> = ({ json, onChange }) => {
  const styles = useStyles();
  const jsonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let jsonEditor: Editor | null = null;
    if (jsonRef.current) {
      jsonEditor = new Editor(
        jsonRef.current,
        {
          modes: ['code', 'tree'],
          onChange: () => {
            try {
              const changedJson = jsonEditor?.get();
              onChange(changedJson);
            } catch {}
          },
        },
        json
      );
    }
    return () => jsonEditor?.destroy();
  }, [json]);

  return (
    <div
      className={styles.container}
      id="react-json-editor-wrapper"
      ref={jsonRef}
    />
  );
};
