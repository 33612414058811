export const paginatedField = <T extends { nodes: S[] }, S>(
  keyArgs: string[]
) => ({
  keyArgs,
  merge(existing: T, incoming: T) {
    if (!existing) {
      return incoming;
    }

    if (!incoming) {
      return existing;
    }

    return {
      ...existing,
      ...incoming,
      nodes: [...existing.nodes, ...incoming.nodes],
    };
  },
});
