import { createStyles, Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITextField from '@material-ui/core/TextField';
import { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField/TextField';
import React from 'react';
import { INPUT_WIDTH } from './utils';

export interface TextFieldProps extends Omit<MUITextFieldProps, 'onChange'> {
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  width?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: (props: { width: number }) => ({
      maxWidth: props.width,
      minWidth: props.width,
    }),
  })
);

export const TextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles({
    width: props.width ?? INPUT_WIDTH,
  });

  const onTextFieldChange: StandardInputProps['onChange'] = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value, e);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <MUITextField {...props} onChange={onTextFieldChange} />
    </FormControl>
  );
};
