import { Typography } from '@material-ui/core';
import React from 'react';
import { Rental } from '../../../common/types/rental';
import { roundToDigits } from '../../../common/utils/roundToDigits';
import { SortablePaginatedTable } from '../../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../../webui/table/sortable-paginated-table/TableHeadCell';
import { generateNumberOfRentalsPerProductMap } from './utils/generateNumberOfRentalsPerProductMap';

interface Props {
  rentals: Rental[];
}

interface RentalsPerProductTableData {
  id: string;
  product: string;
  numberOfBoxes: number;
  numberOfRentals: number;
  numberOfRentalsPerBox: number;
  share: number;
}

const createTableHeadCells = (): TableHeadCell<
  RentalsPerProductTableData
>[] => [
  {
    id: 'product',
    label: 'Product',
    render: (row) => row.product,
  },
  {
    id: 'numberOfRentals',
    label: '#Rentals',
    render: (row) => row.numberOfRentals,
  },
  {
    id: 'numberOfBoxes',
    label: '#In boxes',
    render: (row) => row.numberOfBoxes,
  },
  {
    id: 'numberOfRentalsPerBox',
    label: '#Rentals per box',
    render: (row) => roundToDigits(row.numberOfRentalsPerBox, 1),
  },
  {
    id: 'share',
    label: 'Share',
    render: (row) => `${row.share}%`,
  },
];

export const RentalsPerProductTable: React.FC<Props> = ({ rentals }) => {
  const numberOfRentalsPerProductEntries = Object.entries(
    generateNumberOfRentalsPerProductMap(rentals)
  ).map(([product, numberOfRentals]) => ({
    id: product,
    product,
    numberOfBoxes: numberOfRentals.numberOfBoxes,
    numberOfRentals: numberOfRentals.total,
    numberOfRentalsPerBox: numberOfRentals.perBox,
    share: Math.round((numberOfRentals.total / rentals.length) * 100),
  }));

  if (numberOfRentalsPerProductEntries.length <= 0) {
    return (
      <Typography>{'There were no rentals during this period'}</Typography>
    );
  }

  return (
    <SortablePaginatedTable
      title={'Number of rentals per product'}
      initialRowsPerPage={numberOfRentalsPerProductEntries.length}
      initialOrderByKey={'numberOfRentals'}
      initialOrder={'desc'}
      tableHeadCells={createTableHeadCells()}
      rows={numberOfRentalsPerProductEntries}
    />
  );
};
