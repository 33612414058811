import React from 'react';
import { Pie, PieChart, Tooltip } from 'recharts';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Rental } from '../../../common/types/rental';
import {
  generateRentalsPerBoxPieData,
  RentalsPerBoxPieData,
} from './utils/generateRentalsPerBoxPieData';

interface Props {
  boxes: BoxWithoutRentals[];
  isPublic?: boolean;
  rentals: Rental[];
}

export const RentalsPerBoxPie: React.FC<Props> = ({
  boxes,
  isPublic = false,
  rentals,
}) => {
  return (
    <PieChart width={600} height={600}>
      <Pie
        data={generateRentalsPerBoxPieData(boxes, rentals)}
        dataKey="count"
        label={(data: RentalsPerBoxPieData) =>
          data.count >= 1 ? data.box : undefined
        }
        nameKey="box"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#B40A7A"
      />
      <Tooltip
        // @ts-ignore
        formatter={(
          value: number,
          name: string,
          props: { payload: RentalsPerBoxPieData }
        ) => {
          return isPublic
            ? `${props.payload.share}%`
            : `${props.payload.count} (${props.payload.share}%)`;
        }}
      />
    </PieChart>
  );
};
