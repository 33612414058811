import React from 'react';
import { Marker } from 'react-leaflet';
import { generatePath, useHistory } from 'react-router';
import { Routes } from '../../common/Routes';
import { slottsskogenPosition } from '../../common/utils/slottsskogenPosition';
import {
  GenericMap,
  ZOOM_MANY_MARKERS,
  ZOOM_ONE_MARKER,
} from '../location/map/GenericMap';
import { ActivityAreaMarker } from './types/activityAreaMarker';
import { getActivityAreaMarkerIcon } from './utils/getActivityAreaMarker';

interface Props {
  activityAreaMarkers: ActivityAreaMarker[];
}

export const ActivityAreaMap: React.FC<Props> = ({ activityAreaMarkers }) => {
  const history = useHistory();
  return (
    <GenericMap
      initialPosition={
        activityAreaMarkers.length > 0
          ? [
              activityAreaMarkers[0].coordinates.lat,
              activityAreaMarkers[0].coordinates.long,
            ]
          : slottsskogenPosition
      }
      zoom={
        activityAreaMarkers.length > 1 ? ZOOM_MANY_MARKERS : ZOOM_ONE_MARKER
      }
    >
      {activityAreaMarkers.map((marker) => (
        <Marker
          icon={getActivityAreaMarkerIcon(marker)}
          key={marker.id}
          position={[marker.coordinates.lat, marker.coordinates.long]}
          eventHandlers={{
            click: () => {
              history.push(
                generatePath(Routes.ACTIVITY, {
                  id: marker.id,
                })
              );
            },
          }}
        />
      ))}
    </GenericMap>
  );
};
