import { Typography } from '@material-ui/core';
import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Organisation } from '../../common/types/organisation';
import { User } from '../../common/types/user';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';

interface Props {
  organisations: Organisation[];
  user: User;
}

export const OrganisationsList: React.FC<Props> = ({ organisations, user }) => {
  const userOrganisations = organisations.filter((organisation) =>
    organisation.users.some((u) => u.id === user?.id)
  );

  if (userOrganisations.length === 0) {
    return <Typography>This user is not part of any organisations</Typography>;
  }

  return (
    <>
      {userOrganisations.map((organisation) => (
        <Box>
          <Link
            component={RouterLink}
            to={generatePath(Routes.ORGANISATION, {
              id: organisation.id,
            })}
          >
            {organisation.name}
          </Link>
        </Box>
      ))}
    </>
  );
};
