import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { ProductRentalType } from '../../common/types/product';
import {
  AllProductsDocument,
  useAddProductMutation,
} from '../../generated/graphql';
import { transformProductRentalTypeToGQL } from '../../gql/transformProduct';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import { defaultPriceWeightId } from '../price/priceWeightConstants';
import { PriceWeightSelect } from '../price/PriceWeightSelect';
import { RentalTypeSelect } from './RentalTypeSelect';

interface AddProductProps {}

interface ProductForm {
  description: string;
  imageName: string;
  name: string;
  priceWeight: string;
  rentalType: ProductRentalType;
  translationKey: string;
}

export const AddProduct: React.FC<AddProductProps> = () => {
  const memoizedInitialForm = useMemo<ProductForm>(
    () => ({
      description: '',
      imageName: '',
      name: '',
      priceWeight: defaultPriceWeightId,
      rentalType: 'rent',
      translationKey: '',
    }),
    []
  );
  const { form, reset, updateField } = useForm<ProductForm>(
    memoizedInitialForm
  );

  // TODO: error handling
  const [addProduct, { loading }] = useAddProductMutation();

  const { showNotification } = useNotification();

  const onDescriptionChange: TextFieldProps['onChange'] = (value) => {
    updateField('description', value);
  };

  const onImageNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('imageName', value);
  };

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onPriceWeightChange: SelectInputProps['onChange'] = (e) => {
    updateField('priceWeight', e.target.value as string);
  };

    const onRentalTypeChange = (value: ProductRentalType) => {
      updateField('rentalType', value);
    };

    const onTranslationKeyChange: TextFieldProps['onChange'] = (value) => {
      updateField('translationKey', value);
    };

  const onSave = async () => {
    try {
      const result = await addProduct({
        variables: {
          addProduct: {
            description: form.description,
            imageName: form.imageName,
            name: form.name,
            priceWeight: form.priceWeight,
            rentalType: transformProductRentalTypeToGQL(form.rentalType),
            translationKey: form.translationKey,
          },
        },
        refetchQueries: [{ query: AllProductsDocument }],
      });
      reset();
      showNotification({
        message: 'Product added.',
        severity: 'success',
      });
      console.log('Product added!', result?.data?.addProduct?.product);
    } catch (e) {
      showNotification({
        message: 'Could not add product.',
        severity: 'error',
      });
      console.log('Product add error', e, '--- e');
    }
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add product</Typography>
      <Box mt={2}>
        <TextField
          id={'add-product-name'}
          label={'Name'}
          onChange={onNameChange}
          value={form.name}
        />
      </Box>
      <Box mt={2}>
        <TextField
          id={'add-product-description'}
          label={'Description'}
          onChange={onDescriptionChange}
          value={form.description}
        />
      </Box>
      <Box mt={2}>
        <TextField
          id={'add-product-imageName'}
          label={'Image name'}
          onChange={onImageNameChange}
          value={form.imageName}
        />
      </Box>
      <Box mt={2}>
        <TextField
          id={'add-product-translationKey'}
          label={'Translation key'}
          onChange={onTranslationKeyChange}
          value={form.translationKey}
        />
      </Box>
      <Box mt={2}>
        <PriceWeightSelect
          id={'add-product-priceWeight'}
          value={form.priceWeight}
          onChange={onPriceWeightChange}
        />
      </Box>
      <Box mt={2}>
        <RentalTypeSelect
          id={'add-product-rentalType'}
          value={form.rentalType}
          onChange={onRentalTypeChange}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={loading} onClick={onSave} />
      </Box>
    </Box>
  );
};
