import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { DataList } from '../../webui/DataList';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllPendingUsers } from '../pending-user/gql/useAllPendingUsers';
import { PendingUsersTable } from '../pending-user/PendingUsersTable';
import { EditOrganisationDrawer } from './EditOrganisationDrawer';
import { useOrganisationById } from './gql/useOrganisationById';
import { OrganisationBoxesTable } from './OrganisationBoxesTable';
import { OrganisationUsersTable } from './OrganisationUsersTable';

interface Props {}

export const OrganisationPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: organisation,
    loading: loadingOrganisations,
    error: errorOrganisations,
  } = useOrganisationById({
    variables: { id },
  });
  const {
    data: pendingUsers,
    loading: loadingPendingUsers,
    error: errorPendingUsers,
  } = useAllPendingUsers();

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  if (loadingOrganisations || loadingPendingUsers) {
    return <LoadingSpinnerContainer />;
  }
  if (errorOrganisations || errorPendingUsers) {
    return (
      <div>{errorOrganisations?.message ?? errorPendingUsers?.message}</div>
    );
  }
  if (!organisation) {
    return <div>Could not find organisation information.</div>;
  }

  const items: { value?: string; label: string }[] = [
    {
      label: 'Id',
      value: organisation.id,
    },
    { label: 'Name', value: organisation.name },
    { label: 'Contact Name', value: organisation.contactName },
    { label: 'Contact Email', value: organisation.contactEmail },
    { label: 'City', value: organisation.city },
    { label: 'Country', value: organisation.country },
    { label: 'PhoneNumber', value: organisation.phoneNumber },
    { label: 'ZipCode', value: organisation.zipCode },
  ];

  const filteredPendingUsers = pendingUsers.filter((pu) =>
    pu.organisations.some((o) => o.id === id)
  );

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <DataList
              header={'Organisation information'}
              headerVariant={'h3'}
              items={items}
            />
          </Box>
          <Box>
            <EditOrganisationDrawer
              organisation={organisation}
              open={open}
              onClose={onClose}
            />
          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper>
          <OrganisationUsersTable users={organisation.users} />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <OrganisationBoxesTable boxes={organisation.boxes} />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <PendingUsersTable pendingUsers={filteredPendingUsers} />
        </Paper>
      </Box>
    </Container>
  );
};
