import React from 'react';
import { Button } from '../../../webui/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog } from '../../../webui/Dialog';
import { DialogTitle } from '../../../webui/DialogTitle';
import { DialogContentText } from '../../../webui/DialogContentText';
import { DialogContent } from '../../../webui/DialogContent';
import { DialogActions } from '../../../webui/DialogActions';
import {
  AllBoxesDocument,
  useUnlockCompartmentMutation,
} from '../../../generated/graphql';
import { ButtonWithLoading } from '../../../webui/ButtonWithLoading';
import { useNotification } from '../../../common/notifications/useNotification';

interface UnlockButtonProps {
  compartmentId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unlockedButton: {
      backgroundColor: theme.palette.success.light,
    },
  })
);

export const UnlockButton: React.FC<UnlockButtonProps> = ({
  compartmentId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [unlockCompartment, { loading }] = useUnlockCompartmentMutation({
    refetchQueries: [{ query: AllBoxesDocument }],
  });

  const { showNotification } = useNotification();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClose = () => {
    handleClose();
  };

  const onConfirm = async () => {
    try {
      const result = await unlockCompartment({ variables: { compartmentId } });
      showNotification({
        message: 'Compartment unlocked.',
        severity: 'success',
      });
      console.log(
        'Compartment unlocked!',
        result?.data?.unlockCompartment?.compartment
      );
      handleClose();
    } catch (e) {
      showNotification({
        message: 'Could not unlock compartment.',
        severity: 'error',
      });
      console.log('Compartment lock error', e, '--- e');
    }
  };

  return (
    <>
      <Button className={classes.unlockedButton} onClick={handleClickOpen}>
        Unlock
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unlock the compartment?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to unlock the compartment? All users will be
            able to use it until you lock it again!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Keep it locked
          </Button>
          <ButtonWithLoading
            loading={loading}
            onClick={onConfirm}
            color="primary"
            autoFocus
          >
            Unlock
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
};
