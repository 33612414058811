import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { BoxColor } from '../../common/types/box';
import { resolveCircuitBoardPort } from '../../common/utils/resolveCircuitBoardPort';
import { resolveCoordinates } from '../../common/utils/resolveCoordinates';
import {
  AllBoxesDocument,
  GQL_AddBoxResponseType,
  Maybe,
  useAddBoxMutation,
} from '../../generated/graphql';
import { transformBoxColorToGQL } from '../../gql/transformBox';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Grid } from '../../webui/Grid';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import { AddLocation, LocationForm } from '../location/add/AddLocation';
import { SponsorSelect } from '../sponsor/SponsorSelect';
import { AddCompartment, AddCompartmentForm } from './AddCompartment';
import { BoxColorSelect } from './BoxColorSelect';

interface AddBoxProps {}

interface AddBoxForm {
  circuitBoardId: string;
  color: BoxColor;
  name: string;
  numberOfCompartments: number;
  sponsorId: string;
}

interface CompartmentItem {
  label: string;
  value: number;
}

const numberOfCompartmentOptions: CompartmentItem[] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
].map((number) => ({
  label: String(number),
  value: number,
}));

const initialAddBoxForm: AddBoxForm = {
  circuitBoardId: '',
  color: '#B40A7A',
  numberOfCompartments: 14,
  name: '',
  sponsorId: '',
};

const initialLocationForm: LocationForm = {
  name: '',
  description: '',
  lat: '',
  long: '',
};

const createCompartmentInitialForm = (
  compartmentNumber: number
): AddCompartmentForm => ({
  locked: true,
  circuitBoardPort: String(compartmentNumber - 1),
  name: String(compartmentNumber),
  productId: '',
});

const resolveErrorMessage = (
  type: Maybe<GQL_AddBoxResponseType> | undefined
): string => {
  switch (type) {
    case GQL_AddBoxResponseType.NoPermissionToAddBox:
      return 'No permission to add box';
  }
  return 'Could not add box';
};

export const AddBox: React.FC<AddBoxProps> = () => {
  const { form, reset, updateField } = useForm<AddBoxForm>(initialAddBoxForm);
  const formComp1 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(1), [])
  );
  const formComp2 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(2), [])
  );
  const formComp3 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(3), [])
  );
  const formComp4 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(4), [])
  );
  const formComp5 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(5), [])
  );
  const formComp6 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(6), [])
  );
  const formComp7 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(7), [])
  );
  const formComp8 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(8), [])
  );
  const formComp9 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(9), [])
  );
  const formComp10 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(10), [])
  );
  const formComp11 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(11), [])
  );
  const formComp12 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(12), [])
  );
  const formComp13 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(13), [])
  );
  const formComp14 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(14), [])
  );
  const formComp15 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(15), [])
  );
  const formComp16 = useForm<AddCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(16), [])
  );
  const locationForm = useForm(initialLocationForm);

  const compartmentForms = [
    formComp1,
    formComp2,
    formComp3,
    formComp4,
    formComp5,
    formComp6,
    formComp7,
    formComp8,
    formComp9,
    formComp10,
    formComp11,
    formComp12,
    formComp13,
    formComp14,
    formComp15,
    formComp16,
  ];

  // TODO: error handling
  const [addBox, { loading }] = useAddBoxMutation();

  const { showNotification } = useNotification();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onSponsorChange = (value: string) => {
    updateField('sponsorId', value);
  };

  const onColorChange = (value: BoxColor) => {
    updateField('color', value);
  };

  const onCompartmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    updateField('numberOfCompartments', event.target.value as number);
  };

  const onSave = async () => {
    try {
      const result = await addBox({
        variables: {
          boxInput: {
            compartments: compartmentForms
              .slice(0, form.numberOfCompartments)
              .map((compForm) => {
                return {
                  circuitBoardPort: resolveCircuitBoardPort(
                    compForm.form.circuitBoardPort
                  ),
                  locked: compForm.form.locked,
                  name: compForm.form.name,
                  productId: compForm.form.productId,
                };
              }),
            circuitBoardId: form.circuitBoardId,
            color: transformBoxColorToGQL(form.color),
            name: form.name,
            location: {
              description: locationForm.form.description,
              name: locationForm.form.name,
              coordinates: resolveCoordinates(
                locationForm.form.lat,
                locationForm.form.long
              ),
            },
            sponsorId: form.sponsorId,
          },
        },
        refetchQueries: [{ query: AllBoxesDocument }],
      });
      if (result?.data?.addBox?.type === 'Success') {
        reset();
        for (const compartmentForm of compartmentForms) {
          compartmentForm.reset();
        }
        locationForm.reset();
        showNotification({
          message: 'Box added.',
          severity: 'success',
        });
      } else {
        showNotification({
          message: resolveErrorMessage(result?.data?.addBox?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not add box.',
        severity: 'error',
      });
      console.log('Box add error', e, '--- e');
    }
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add box</Typography>
      <Box display={'inline-flex'} flexDirection={'row'}>
        <Box>
          <TextField label={'Name'} onChange={onNameChange} value={form.name} />
        </Box>
        <Box ml={2}>
          <Select
            label={'Compartments'}
            id="box-compartments"
            value={form.numberOfCompartments}
            onChange={onCompartmentChange}
          >
            {numberOfCompartmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box ml={2}>
          <BoxColorSelect
            id={'box-color'}
            value={form.color}
            onChange={onColorChange}
          />
        </Box>
        <Box ml={2}>
          <SponsorSelect
            id={'box-sponsor'}
            value={form.sponsorId}
            onChange={onSponsorChange}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <AddLocation
          form={locationForm.form}
          updateField={locationForm.updateField}
        />
      </Box>
      <Box mt={2}>
        <Typography variant={'h5'}>Compartments</Typography>
      </Box>
      <Box mt={2}>
        <Grid container spacing={1}>
          {compartmentForms
            .slice(0, form.numberOfCompartments)
            .map((compForm, index) => {
              return (
                <Grid key={index} item>
                  <AddCompartment
                    updateField={compForm.updateField}
                    form={compForm.form}
                    idPrefix={String(index + 1)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>

      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={loading} onClick={onSave} />
      </Box>
    </Box>
  );
};
