import { Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { UserLite } from '../../common/types/user';
import { Button } from '../../webui/Button';
import { CheckboxWithLabel } from '../../webui/inputs/CheckboxWithLabel';
import { ToggleOpen } from '../../webui/ToggleOpen';
import { Typography } from '../../webui/Typography';
import { allKeys, generateUsersCsv, UserKey } from './util/generateUsersCsv';

interface Props {
  users: UserLite[];
}

const useStyles = makeStyles(() =>
  createStyles({
    checkboxes: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: {
      flex: 1,
    },
    container: {
      padding: 24,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);

export const UserExport: React.FC<Props> = ({ users }) => {
  const [open, setOpen] = useState(false);
  const [keysToUse, setKeysToUse] = useState<UserKey[]>([]);
  const classes = useStyles();

  const onChange = (key: UserKey, addKey: boolean) => {
    if (addKey) {
      setKeysToUse([...keysToUse, key]);
    } else {
      const indexOfKey = keysToUse.indexOf(key);
      setKeysToUse([
        ...keysToUse.slice(0, indexOfKey),
        ...keysToUse.slice(indexOfKey + 1),
      ]);
    }
  };

  const downloadCsv = () => {
    const csv = generateUsersCsv(users, keysToUse);
    const a = document.createElement('a');
    a.href = `data:text/csv;base64,${btoa(csv)}`;
    a.download = 'user_export.csv';
    a.click();
    a.remove();
  };

  const onToggle = () => setOpen((o) => !o);
  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h5" component="div">
          Columns to export
        </Typography>
        <ToggleOpen open={open} onToggle={onToggle} />
      </div>
      {open && (
        <>
          <div className={classes.checkboxes}>
            {allKeys.map((key) => (
              <div className={classes.checkbox}>
                <CheckboxWithLabel
                  onChange={(value) => onChange(key, value)}
                  value={keysToUse.includes(key)}
                  label={key}
                />
              </div>
            ))}
          </div>
          <Button onClick={downloadCsv}>Export as csv</Button>
        </>
      )}
    </Paper>
  );
};
