import React, { useState } from 'react';
import { Activity } from '../../common/types/activityArea';
import { Box } from '../../webui/Box';
import { MultiSelect, MultiSelectProps } from '../../webui/inputs/MultiSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';

export interface ActivitiesSelectProps {
  activities: Activity[];
  initialActivities?: string[];
  onChange: MultiSelectProps['onChange'];
}

export const ActivitiesSelect: React.FC<ActivitiesSelectProps> = ({
  activities,
  initialActivities,
  onChange,
}) => {
  const [newActivities, setNewActivities] = useState<string>('');
  const [selectedActivities, setSelectedActities] = useState<string[]>(
    initialActivities ?? []
  );

  const onActivitiesChange: MultiSelectProps['onChange'] = (value, e) => {
    setSelectedActities(value);
    const activities = [...value, ...newActivities.split(',')].filter(Boolean);
    onChange(activities, e);
  };
  const onNewActivitiesChange: TextFieldProps['onChange'] = (value, e) => {
    setNewActivities(value);
    const activities = [...selectedActivities, ...value.split(',')].filter(
      Boolean
    );
    onChange(activities, e);
  };

  return (
    <>
      <Box mr={1} mt={1}>
        <MultiSelect
          id="edit-box-activities"
          label="Activities"
          value={selectedActivities}
          options={activities.map(({ code }) => ({
            value: code,
            label: code,
          }))}
          onChange={onActivitiesChange}
        />
      </Box>
      <Box mr={1} mt={1}>
        <TextField
          id="edit-box-new-activities"
          label="New activities"
          placeholder={'activity1,activity2'}
          onChange={onNewActivitiesChange}
        />
      </Box>
    </>
  );
};
