import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Form } from '../../common/hooks/useForm';
import { AreaInfo } from '../../common/types/activityArea';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { DeleteForever } from '../../webui/icons/DeleteForever';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';

export interface EditAreaInfosProps {
  form: AreaInfosForm;
  updateField: Form<AreaInfosForm>['updateField'];
}

export interface AreaInfosForm {
  areaInfos: AreaInfo[];
}

export const EditAreaInfos: React.FC<EditAreaInfosProps> = ({
  form,
  updateField,
}) => {
  const [areaInfos, setAreaInfos] = useState(form.areaInfos);

  useEffect(() => {
    updateField('areaInfos', areaInfos);
  }, [areaInfos, updateField]);

  const onChange = (i: number, field: string): TextFieldProps['onChange'] => (
    value
  ) => {
    setAreaInfos([
      ...areaInfos.slice(0, i),
      {
        ...areaInfos[i],
        [field]: field === 'count' ? Number(value) : value,
      },
      ...areaInfos.slice(i + 1),
    ]);
  };

  const onDelete = (i: number) => () => {
    setAreaInfos([...areaInfos.slice(0, i), ...areaInfos.slice(i + 1)]);
  };

  const onAdd = () => {
    setAreaInfos([
      ...areaInfos,
      {
        id: '',
        type: '',
        count: 0,
      },
    ]);
  };

  return (
    <Box>
      <Typography variant={'h5'}>Area infos</Typography>
      {areaInfos.map((areaInfo, i) => (
        <Box display={'flex'} flexWrap={'wrap'}>
          <Box mr={1} mt={1}>
            <TextField
              label={'Type'}
              value={areaInfo.type}
              onChange={onChange(i, 'type')}
            />
          </Box>
          <Box mr={1} mt={1}>
            <TextField
              label={'Count'}
              value={areaInfo.count}
              onChange={onChange(i, 'count')}
            />
          </Box>
          <Box mr={1} mt={2}>
            <IconButton onClick={onDelete(i)}>
              <DeleteForever />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box ml={10} mt={2}>
        <Button onClick={onAdd}>
          <Add />
          Add area info
        </Button>
      </Box>
    </Box>
  );
};
