import React from 'react';
import {
  createStyles,
  Input,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Theme,
  useTheme,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MenuItem } from './MenuItem';
import { INPUT_WIDTH } from './utils';

interface Option {
  value: string;
  label: string;
}

export interface MultiSelectProps
  extends Omit<MUISelectProps, 'onChange' | 'value'> {
  onChange: (
    value: string[],
    event: React.ChangeEvent<{ value: unknown }>
  ) => void;
  value: string[];
  options: Option[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: INPUT_WIDTH,
      minWidth: INPUT_WIDTH,
    },
  })
);

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  onChange,
  options,
  value,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const labelId = `${props.id}-label`;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string[], event);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="{labelId}">{label}</InputLabel>
      <MUISelect
        {...props}
        labelId={labelId}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option.value, value, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
