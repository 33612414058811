import React from 'react';
import { CircularProgress } from './CircularProgress';
import { Box } from '../Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface LoadingSpinnerContainerProps {
  color?: string;
  size?: number;
}

const useStyles = makeStyles<Theme, { color: string | undefined }>(
  (theme: Theme) =>
    createStyles({
      spinner: {
        color: ({ color }) => color,
      },
    })
);

export const LoadingSpinnerContainer: React.FC<LoadingSpinnerContainerProps> = ({
  color,
  size = 40,
}) => {
  const classes = useStyles({ color });

  return (
    <Box
      className={classes.spinner}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexGrow={1}
      p={1}
    >
      <CircularProgress color={color ? 'inherit' : undefined} size={size} />
    </Box>
  );
};
