import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
} from 'recharts';
import { Rental } from '../../common/types/rental';
import {
  generateRentalAgeData,
  RentalAgeData,
} from './rentals/utils/generateRentalAgeData';

interface Props {
  rentals: Rental[];
}

export const RentalsPerAge: React.FC<Props> = ({ rentals }) => {
  const data = generateRentalAgeData(rentals);
  return (
    <ComposedChart
      width={1000}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="label" scale="band" />
      <Tooltip
        // @ts-ignore
        formatter={(
          value: number,
          name: string,
          props: { payload: RentalAgeData }
        ) => {
          return `(${Math.round(
            (props.payload.count / rentals.length) * 100
          )}%)`;
        }}
      />
      <Bar dataKey="count" barSize={20} fill="#B40A7A" />
      <Line type="monotone" dataKey="count" stroke="#B40A7A" />
    </ComposedChart>
  );
};
