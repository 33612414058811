import React from 'react';
import MUIDrawer from '@material-ui/core/Drawer';

interface DrawerProps extends React.ComponentProps<typeof MUIDrawer> {}

export const Drawer: React.FC<DrawerProps> = ({
  anchor = 'right',
  ...props
}) => {
  return <MUIDrawer {...props} anchor={anchor} />;
};
