import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { BatteryMap } from './BatteryMap';
import { DetailedBatteryReadingsTables } from './DetailedBatteryReadingsTables';
import { useAllBatteryReadings } from './gql/useAllBatteryReadings';
import { LatestBatteryReadingPerBoxTable } from './LatestBatteryReadingPerBoxTable';
import { getLatestBatteryReadingPerBox } from './utils/getLatestBatteryReadingPerBox';
import { resolveBatteryStatuses } from './utils/resolveBatteryStatuses';

interface Props {}

export const BoxBatteryHistoryPage: React.FC<Props> = () => {
  const {
    data: batteryReadings,
    loading: loadingBatteryReadings,
  } = useAllBatteryReadings();

  const [
    showDetailedBatteryReadingsTables,
    setShowDetailedBatteryReadingsTables,
  ] = useState(false);

  if (loadingBatteryReadings) {
    return <LoadingSpinnerContainer />;
  }

  const onToggleShowDetailedBatteryReadingsTables = () => {
    setShowDetailedBatteryReadingsTables((prevState) => !prevState);
  };

  const boxesWithLatestReading = getLatestBatteryReadingPerBox(batteryReadings);

  return (
    <div>
      <Paper>
        <Box mb={2}>
          <BatteryMap
            batteryStatuses={resolveBatteryStatuses(
              Object.values(boxesWithLatestReading)
            )}
          />
        </Box>
      </Paper>
      <Paper>
        <Box mb={2}>
          <LatestBatteryReadingPerBoxTable
            readings={Object.values(boxesWithLatestReading)}
          />
        </Box>
      </Paper>
      <Paper>
        <Box display={'flex'} p={2} justifyContent={'center'}>
          <Button onClick={onToggleShowDetailedBatteryReadingsTables}>
            {showDetailedBatteryReadingsTables
              ? 'Hide detailed tables'
              : 'Show detailed tables'}
          </Button>
        </Box>
        {showDetailedBatteryReadingsTables && (
          <DetailedBatteryReadingsTables batteryReadings={batteryReadings} />
        )}
      </Paper>
    </div>
  );
};
