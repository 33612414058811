import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { formatISO } from '../../common/utils/dateUtils';
import { resolveDisplayPrice } from '../../common/utils/resolveDisplayPrice';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { PriceStructure } from './PriceStructure';
import { defaultPriceWeight } from './priceWeightConstants';
import { resolvePrice } from './resolvePrice';

interface Props {
  priceStructure: PriceStructure;
}

const useStyles = makeStyles({
  table: {
    width: 650,
  },
});

type PriceTableData = {
  hour: string;
  price: number;
  priceExampleFullHour: number;
};

const createData = (
  hour: string,
  price: number,
  priceExampleFullHour: number
): PriceTableData => ({
  hour,
  price,
  priceExampleFullHour,
});

const createRows = (priceStructure: PriceStructure): PriceTableData[] =>
  priceStructure
    ? [
        createData(
          'Start price',
          priceStructure.start,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 10:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '1',
          priceStructure.firstHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 11:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '2',
          priceStructure.secondHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 12:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '3',
          priceStructure.thirdHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 13:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '4',
          priceStructure.fourthHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 14:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '5',
          priceStructure.fifthHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 15:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '6',
          priceStructure.sixthHour,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 16:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
        createData(
          '6+',
          priceStructure.penalty,
          resolvePrice(
            priceStructure,
            formatISO(new Date('2000-01-01 10:00')),
            formatISO(new Date('2000-01-01 17:00')),
            defaultPriceWeight,
            defaultPriceWeight,
            defaultPriceWeight
          )
        ),
      ]
    : [];

export const PriceStructureTable: React.FC<Props> = ({ priceStructure }) => {
  const classes = useStyles();

  const rows = createRows(priceStructure);

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Hour</TableCell>
            <TableCell align="right">Price hour</TableCell>
            <TableCell align="right">Price all hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.hour}>
              <TableCell component="th" scope="row">
                {row.hour}
              </TableCell>
              <TableCell align="right">
                {resolveDisplayPrice(row.price, 'sek')}
              </TableCell>
              <TableCell align="right">
                {resolveDisplayPrice(row.priceExampleFullHour, 'sek')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
