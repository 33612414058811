import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../../common/notifications/useNotification';
import { Box } from '../../webui/Box';
import { ButtonWithLoading } from '../../webui/ButtonWithLoading';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../webui/inputs/CheckboxWithLabel';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { JSONEditor } from './JSONEditor';

interface Props {}

const useStyles = makeStyles(() =>
  createStyles({
    jsonContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
    },
    json: {
      margin: 8,
      padding: 8,
      width: '100%',
    },
    jsonHeader: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
  })
);

export const EditTranslationsPage: React.FC<Props> = () => {
  const styles = useStyles();

  const [initialTranslations, setInitialTranslations] = useState<
    Record<string, Object | null>
  >({ en: null, sv: null });
  const [translations, setTranslations] = useState<
    Record<string, string | null>
  >({ en: null, sv: null });

  const [translationsLoading, setTranslationsLoading] = useState<number>(0);
  const [savingTranslations, setSavingTranslations] = useState(false);

  const languages = [
    { id: 'en', label: 'English' },
    { id: 'sv', label: 'Swedish' },
  ];

  const [selectedLanguages, setSelectedLanguages] = useState<
    Record<string, boolean>
  >(
    languages.reduce(
      (acc, l) => ({
        ...acc,
        [l.id]: true,
      }),
      {}
    )
  );

  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchTranslations = async (language: 'en' | 'sv') => {
      setTranslationsLoading((n) => n + 1);
      const token = await getAuth().currentUser?.getIdToken();

      const resp = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/translations`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            'accept-language': language,
          },
        }
      );
      const json = await resp.json();

      setInitialTranslations((t) => ({
        ...t,
        [language]: JSON.parse(json) ?? {},
      }));
      setTranslationsLoading((n) => n - 1);
    };

    fetchTranslations('en');
    fetchTranslations('sv');
  }, []);

  if (translationsLoading > 0) {
    return <LoadingSpinnerContainer />;
  }

  const handleChange = (lang: string) => (json: any) => {
    setTranslations((t) => ({
      ...t,
      [lang]: json,
    }));
  };

  const handleChangeSelectedLanguage = (
    lang: string
  ): CheckboxWithLabelProps['onChange'] => (selected) => {
    setSelectedLanguages((langs) => ({
      ...langs,
      [lang]: selected,
    }));
  };

  const onSave = async () => {
    setSavingTranslations(true);
    const token = await getAuth().currentUser?.getIdToken();

    const updatedTranslations = Object.entries(translations).filter(
      ([lang, translations]) => selectedLanguages[lang] && translations !== null
    );

    for (const [lang, translation] of updatedTranslations) {
      const resp = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/setTranslations`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            language: lang,
            translations: JSON.stringify(translation),
          }),
        }
      );

      if (resp.status === 200) {
        showNotification({
          message: `${lang} updated!`,
          severity: 'success',
        });

        setInitialTranslations((t) => ({
          ...t,
          [lang]: translations[lang],
        }));
        setTranslations((t) => ({
          ...t,
          [lang]: null,
        }));
      } else {
        showNotification({
          message: `Something went wrong when saving ${lang}`,
          severity: 'error',
        });
      }
    }

    setSavingTranslations(false);
  };

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        p={2}
        justifyContent={'space-between'}
      >
        <Box>
          {languages.map((lang) => (
            <CheckboxWithLabel
              key={`lang-checkbox-${lang.id}`}
              onChange={handleChangeSelectedLanguage(lang.id)}
              value={selectedLanguages[lang.id]}
              label={lang.label}
            />
          ))}
        </Box>
        <ButtonWithLoading
          loading={savingTranslations}
          disabled={languages.every(
            (lang) =>
              !selectedLanguages[lang.id] || translations[lang.id] === null
          )}
          onClick={onSave}
        >
          Save
        </ButtonWithLoading>
      </Box>
      <Box className={styles.jsonContainer}>
        {Object.entries(selectedLanguages).map(([lang, enabled]) => {
          const jsonValues = initialTranslations[lang];
          if (jsonValues === null) {
            return null;
          }

          return (
            <Paper
              className={styles.json}
              key={`json-editor-${lang}`}
              style={{ display: enabled ? 'block' : 'none' }}
            >
              <Box className={styles.jsonHeader}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <Typography variant={'h3'}>
                    {languages?.find((l) => l.id === lang)?.label ?? ''}
                  </Typography>
                  <Typography variant={'h6'}>
                    {translations[lang] !== null ? ' (has changes)' : null}
                  </Typography>
                </Box>
              </Box>
              <JSONEditor json={jsonValues} onChange={handleChange(lang)} />
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};
